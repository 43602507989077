
import { Component, Prop, Vue, Emit } from "vue-property-decorator";

@Component({})
export default class HeaderBar extends Vue {
  @Prop({ default: false }) isMobileMenuOpen!: boolean;

  @Emit("mobileOpenChanged")
  onMobileOpenChanged(): boolean {
    return !this.isMobileMenuOpen;
  }
}
