
import { ErrorReportingService, WelcomeImageService } from "@/services";
import { AxiosUtil } from "@/utils";
import { Component, Prop, Vue } from "vue-property-decorator";
import { WelcomeImage } from "@/types";

@Component({})
export default class Home extends Vue {
  @Prop({ default: false }) isMobileMenuOpen!: boolean;

  private activeBackgroundImage = "";
  private cevianerLinkText = "Cevianer/In";
  private interessierteLinkText = "Interessierte";
  private cevianerLinkTo = "/cevianer";
  private interessierteLinkTo = "/interessierte";
  private welcomeImages: WelcomeImage[] = [];
  private service: WelcomeImageService = new WelcomeImageService(
    AxiosUtil.getCockpitInstance()
  );
  private errorService: ErrorReportingService = new ErrorReportingService();

  async mounted(): Promise<void> {
    this.initResponsiveLayout();
    await this.loadData();
  }

  initResponsiveLayout(): void {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  }

  async loadData(): Promise<void> {
    try {
      this.welcomeImages = await this.service.getImages();
      const index = Math.floor(Math.random() * this.welcomeImages.length);
      const file: WelcomeImage = this.welcomeImages[index];
      this.activeBackgroundImage = file.url;
    } catch (err) {
      this.errorService.report(err);
    }
  }

  onMobileNaviagation(): void {
    this.$emit("mobileOpenChanged", false);
  }

  onResize(): void {
    if (window.innerWidth <= 768) {
      this.cevianerLinkText = "Mehr Info";
      this.interessierteLinkText = "Mehr Info";
      this.cevianerLinkTo = "/cevianer";
      this.interessierteLinkTo = "/interessierte";
    } else {
      this.cevianerLinkText = "Cevianer/In";
      this.interessierteLinkText = "Interessierte";
      this.cevianerLinkTo = "#";
      this.interessierteLinkTo = "#";
    }
  }
}
